import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { NavController, IonButton, IonIcon } from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import { chevronBackOutline } from 'ionicons/icons';

@Component({
  selector: 'mbeon-pwa-nav-back-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslocoModule, IonButton, IonIcon],
  templateUrl: './nav-back-button.component.html',
  styleUrls: ['./nav-back-button.component.scss'],
})
export class NavBackButtonComponent {
  readonly #navController: NavController = inject(NavController);

  constructor() {
    addIcons({ chevronBackOutline });
  }

  async goBack(): Promise<void> {
    await this.#navController.back();
  }
}
